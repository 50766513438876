.teamsWrapper {
    padding-top: 20px;

    .header {

        .add_btn_div {
            text-align: right;

            .btnNew {
                border-radius: 100px;
                border: none;
                padding: 10px 40px;
                background-color: #42D6C9;
                color: #043854;
            }
        }

        .header_first {
            text-align: left;

            .teams_header {
                font-size: 20px;
                color: #0D1220;
                font-weight: 500;
            }
        }
    }

    .teams_table {
        margin-top: 30px;

        thead {
            th {
                border-bottom: none;
                border-top: none;
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                color: #0D1220;
            }
        }

        td {
            border-top: none;
            text-align: left;

            .more_options {
                color: #8292AA;
                cursor: pointer;
                font-size: 14px;
                margin-right: 2px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .edit_btn {
                color: #8292AA;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .member_status {
                width: 130px;
                text-align: left;
            }
        }
    }
}

.addTeamsWrapper {
    text-align: left;

    .gobackdiv {
        text-align: left;
        cursor: pointer;
        margin-bottom: 30px;

        .backIcon {
            margin-right: 5px;
        }
    }

    .addTeamMemberHeader {
        margin-bottom: 20px;
    }
    .team-loader {
        vertical-align: middle;
        margin-left: 10px
    }
    .addTeamTitle {
        font-weight: normal;
        font-size: 12px;
        color:#526179;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .name {
        width: 400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .email {
        width: 400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;

        &.form-control:disabled {
            background-color: #f5f5f5;
        }
    }

    .addRoleBtn {
        text-align: left;

        .btn-primary {
            text-align: center;
            padding-top: 5px;
            box-sizing: border-box;
            margin-bottom: 10px;
            width: 200px;
            text-align: left;
            background: #ffffff;
            border-radius: 8px;
            color: #0D1220;
            border: 1px solid #EEF3F8;
            height: 60px;
            box-sizing: border-box;

        }
    }

    .teamChannelAccessList {
        margin-bottom: 10px;

        ul {
            padding-left: 0px;

            li {
                list-style-type: none;
                display: flex;

                label {
                    padding-left: 8px;
                }
                .channel-type{
                    display: inline-block;
                    margin-right: 5px;

                    .icon {
                        color: #074465;
                    }
                }
            }
        }

        .save_btn {
            margin-top: 10px;
            border-radius: 100px;
            border: none;
            padding: 10px 40px;
            background-color: #42D6C9;
            color: #043854;
        }
    }

    .alert {
        max-width: 500px;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
        margin-left: 20px;
        
    }
}