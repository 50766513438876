.textheaderRow {
    margin-right: 0;
    margin-left: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 5px;

    .tagCol {
        p.tags {
            margin-bottom: 0;
            cursor: pointer;
            display: inline-block;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 1px 5px;
            font-size: 14px;
            margin-right: 5px;

            .tagNameStyle {
                font-size: 12px;
            }

            .deleteTag {
                margin-left: 7px;
                color: #888;
            }
        }
    }

    .textheaderCol {
        padding-right: 0px;
        padding-left: 0px;
        display: grid;
        grid-template-columns: 1fr auto;

        .textheader {
            font-size: 14px;
            text-align: left;
            margin-left: 0;
            text-align: left;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.dashboardHeader {
    padding: 0 10px;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    border-bottom: 1px solid #DBE2ED;
    //position: sticky;
    height: 85px;

    .dashboardRow {
        margin-right: -0;
        margin-left: -0;
    }

    .sectionName {
        display: grid;
        grid-template-columns: 120px auto;

        .chat-info {
            margin-top: 20px;
            margin-left: 10px;
            font-size: 14px;
            color: #043854;
            cursor: pointer;
        }
        .markCompleteBox {
            box-sizing: border-box;
            border-radius: 8px;
            margin-bottom: 8px;
            padding: 4px 10px;
            width: 120px;
            text-align: left;
            margin-top: 10px;
            border: 1px solid #DBE2ED;

            &:hover {
                color: #5f6062;
            }

            &.reopen {
                background-color: transparent;
                border: 1px solid #F69195;
                width: 150px;
            }
            &.chat-widgets {
                width: auto;
                pointer-events: none;
                border: 1px solid #ddd;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }

    .tickSquare {
        width: 24px;
        position: relative;
        bottom: 1px;
    }

    .markCompleteText {
        display: inline-block;
        font-size: 14px;
        margin-left: 4px;

    }

    .dashboardTray {
        text-align: right;
        padding-right: 0;
        padding-left: 0;
        padding-top: 10px;
        position: relative;

        .team-alert{
            position: absolute;
            width: auto;
            text-align: left;
            text-transform: capitalize;
            left: -75px;
        }
        .userTags {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: #043854;
            }
        }

        .ticketAssigned{
            border: 1px solid #DBE2ED;
            border-radius: 8px;
            display: inline-block;
            font-size: 14px;
            margin-left: 5px;
            padding: 0 4px;

            .assignedIcon{
                display: inline-block;
            }

            .type_btn{
                display: inline-block;
            }

            button.dropdown-toggle{
                background-color: transparent;
                color: #043854;
                border: none;
                padding: 5px 10px;
                font-size: 14px;
                
                .member_name{
                    display: inline-block;
                    width: 127px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .custom_icon {
            cursor: pointer;
            display: inline-block;
            border: 1px solid #ddd;
            border-radius: 80px;
            width: 80px;
            font-size: 12px;
            padding: 2px;
            color: #526179;
            width: 80px;
            text-align: center;
            margin-right: 5px;

            &:hover {
                border-color: #043854;
            }
        }

        .userProfile {
            margin-left: 20px;
            margin-right: 10px;
        }

        .menu {
            margin-left: 20px;
            margin-right: 10px;
        }
    }
}


.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.notificationBanner {
    background-color: #ffe564;
    color: #043854;
    font-size: 14px;
    padding: 2px;
    font-weight: 400;
}

.tags-model-content {

    .modal-content {
        width: 400px;

        .createTagsModal {
            padding: 5px;

            .label_list {
                margin-top: 10px;
            }

            .create_new_tag_btn {
                color: #000;
                background-color: #42D6C9;
                border: none;
                border-radius: 18px;
                font-size: 14px;
            }
        }
    }
}