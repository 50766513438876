.stepper-wrapper {

    ._stepper {
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 10px;
        background-color: #fff;
        padding: 20px 30px;
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;

        .step_indicator {
            display: inline-block;
            border-bottom: 2px solid #ddd;
            height: 3px;
            position: relative;
            top: 12px;
        }

        .stepper_col {
            padding-right: 0;
            padding-left: 0;
            text-align: center;

            .checkIcon {
                font-size: 18px;
                color: #ddd;

                &.success {
                    color: #42D6C9;
                }
            }

            .text {
                font-size: 18px;
                display: inline-block;
                margin-left: 8px;
            }
        }
    }
}