@import "~bootstrap/scss/bootstrap";
@import "./components/login/login.component.scss";
@import "./components/common/header/header.component.scss";
@import "./components/dashboard/dashboard.scss";
@import "./components/sidebar/sidebar.component.scss";
@import "./components/tickets/tickets.scss";
@import "./components/ticketsList/ticketList.scss";
@import "./components/platformsList/platformsList.scss";
@import "./components/rightSideBar/rightSideBar.scss";
@import "./components/ticketConversation/ticketConversation.scss";
@import "./components/ticketConversation/whatsapp/whatsapp.scss";
@import "./components/dashboard/dashboardHeader.scss";
@import "./components/settings/settings.scss";
@import "./components/settings/channels/channels.scss";
@import "./components/settings/integrations/integration.scss";
@import "./components/rightSideBar/updateOrderStatus.scss";
@import "./components/settings/profile/profiledetails.scss";
@import "./components/settings/channels/customChannelSetup.scss";
@import "./components/sidebar/notification.component.scss";
@import "./components/common/createEmail/createEmail.scss";
@import "./components/notFound.scss";
@import "./components/settings/channels/whatsapp/whatsapp.scss";
@import "./components/settings/billing/billing.scss";
@import "./components/common/components/stepper.scss";
@import "./components/settings/channels/emails/emailCh.scss";
@import "./components/settings/teams/teams.scss";
@import "./components/navbar/navbar.scss";
@import "./components/marketing/marketing.scss";
@import "./components/marketing/whatsapp/whatsappCreation.scss";

body {
    overflow-x: hidden;
    overflow-y: hidden;
}

button,
input {

    &:active,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}


.App {
    text-align: center;
    font-size: 15px;
}

.displayInline {
    display: inline-block;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.mezchip-tooltip {

    .introjs-tooltiptext {
        padding: 0px 20px;
    }

    .introjs-bullets {
        padding-top: 5px;
        padding-bottom: 0px;
    }

    .introjs-prevbutton {
        padding: 0.2rem 1rem;
        text-shadow: none;
        border: 1px solid #42D6C9;
        background-color: #42D6C9;
        color: black;
        font-size: 15px;
    }

    .introjs-nextbutton {
        padding: 0.2rem 1rem;
        text-shadow: none;
        border: 1px solid #42D6C9;
        background-color: #42D6C9;
        color: black;
        font-size: 15px;
    }

}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.noMargin {
    margin-left: 0;
    margin-right: 0;
}

@media screen and (max-width:520px) {
    .App {
        text-align: center;
        font-size: 13px;
        overflow: auto;
    }
    body{
        overflow-y: scroll;
    }
    
}